module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":0.4,"once":true,"disable":false,"selector":"[data-sal]","animateClassName":"sal-animate","disabledClassName":"sal-disabled","rootMargin":"0% 50%","enterEventName":"sal:in","exitEventName":"sal:out"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"iamjmitch","accessToken":"MC5YNEdyOEJVQUFHdVpuZ0h2.WXl1fu-_ve-_ve-_ve-_ve-_vTrvv71g77-9HO-_ve-_vQlQ77-9SO-_vTcQYAdJMx1lIAMW","schemas":{"about_blurb":{"Main":{"blurb_heading":{"type":"StructuredText","config":{"single":"heading1, heading2, heading3, heading4, heading5, heading6","label":"Blurb Heading"}},"avatar":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"avatar"}},"blurb":{"type":"StructuredText","config":{"multi":"preformatted, heading1, heading2, heading3, heading4, heading5, heading6, strong, em, hyperlink, image, embed, list-item, o-list-item, rtl","label":"blurb"}}}},"tech":{"Main":{"logo":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"logo"}},"hover_text":{"type":"StructuredText","config":{"single":"paragraph","label":"hover_text"}},"order_number":{"type":"Number","config":{"label":"order_number"}}}},"portfolio_piece":{"Main":{"website_image":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"website_image"}},"website_name":{"type":"StructuredText","config":{"single":"heading1, heading2, heading3, heading4, heading5, heading6","label":"website_name"}},"website_blurb":{"type":"StructuredText","config":{"multi":"paragraph, preformatted, heading1, heading2, heading3, heading4, heading5, heading6, strong, em, hyperlink, image, embed, list-item, o-list-item, rtl","label":"website_blurb"}},"website_link":{"type":"Link","config":{"label":"website_link"}},"website_featured":{"type":"Boolean","config":{"default_value":false,"label":"website_featured"}},"featured_position":{"type":"Number","config":{"label":"featured_position"}},"backgroundcolor":{"type":"Color","config":{"label":"backgroundColor"}}}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/avatar.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"4173283c7626db9383ae46b51bab7d95"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
